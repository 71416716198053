
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400);
@import url(https://fonts.googleapis.com/css?family=Poiret+One);
@import url(https://fonts.googleapis.com/css?family=Lato:100,300);
@import url(https://fonts.googleapis.com/css?family=Lora);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,900);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
body {padding:0;margin:0;}

.navbar { margin:0; }
p.intro { color:#8068ab;font-size:30px;margin:0px 0;font-weight:300;padding:15px 20px; }
.sign-up-section .btn-primary {padding:15px 50px;font-size:22px;}
.panel-default .panel-heading.bg-purple, .bg-purple, .bg-purple { background: #8068ab; color: #ffffff; }
.form-control {font-size: 20px;padding: 10px 12px;color: #777;height: auto;}

.hero { margin:0;padding:0; }
.section { background:$body-bg;padding:2em 1em;}
#b2.section { padding-bottom:0; }
.section.dark {
    color:#fdfcf6;text-align:center;
    background:url('/images/dark_header.png');
    background-size: 100% bottom;
}
.section .title { font-family:$title-font; font-size:260%; }
.section .content p { font-size:140%;}

.hero .heroRight       { padding-top:10px;}
.hero .section .title  { font-family:$title-font; font-size:150%; }
.hero .section p       { font-size:85%;}

.panel-default > .panel-heading { background:#8e4593;font-size:140%;color:white;}

#digital-content.section {
    // background:url('/image/digitalcontent2.jpg') center center fixed;
    background:#CC0019;
    color:#fdfcf6;
    background-size: 100% auto;
}
#digital-content2.section {
    // background:url('/image/digitalcontent2.jpg') center center fixed;
    // background:#CC0019;
    background:#8e4593;
    color:#fdfcf6;
    background-size: 100% auto;
}
#mainContent ul li { margin:1em 0; }
#mainContent ul li i { opacity:0.5;}

a { color:#c11ed0; }

/****** home ******/
#buynow {
    display: block;
}
#buynow .secondContent {
  background: #f7f5fa;border: 1px dotted #c39efa;margin:30px auto;padding:0 0 30px 0;max-width: 841px;
}
#buynow .classHighlight {
  text-align: center;font-size: 30px;color: #635184;font-family: 'Poiret One',cursive;
}
#buynow .btn-primary {
  padding: 10px 20px;font-size: 20px;color: #fff;background-color:#ab47bc;border: none;border-color: #2e6da4;
}

.panel-default { border:none;margin-top:2em;margin-bottom:1em; }
.section .content .panel-default p { font-size:120%; margin: .5em 0 1em 0;}
.tx-purple  {color:#c11ed0;}
.tx-red     {color:#DA3B28;}
.section p span.large { font-size:200%; }
.navbar-brand { color: #5c6bc0;font-weight: 300;font-family: Lato;text-transform: uppercase; }
.standout { border-top: 2px dotted #8e4593; border-bottom: 2px dotted #8e4593; padding:1em 0; margin:1em 0 2em 0;}
.section .content .standout p { font-size:110%;font-weight:300; }
.section .content blockquote { font-size:100%;}

.thumbnails a img { border: 4px dotted #ecebf5; }
.thumbnails a img:hover { border: 4px dotted #8068ab; }

#screenshot_bg_purple .thumbnails a img { border: 4px dotted #8e4593; }
#screenshot_bg_purple .thumbnails a img:hover { border: 4px dotted #ecebf5; }

.bottomnav { margin-top:20px; }
.doorTitle { color:#8e4593;}
.calloutBtn { background:#8e4593;border:none;font-size:28px; padding-left:2em; padding-right:2em; cursor:pointer;}
.calloutBtn:hover { background:#682c6c;}
p.copyread { font-family:$font-family-serif; }

h2.bigtext { color:#8e4593; font-family:$font-titles;font-weight:900;font-size:42px;line-height:48px;margin-bottom:0;margin-top:0;padding-top:0;}
.bigtext_subtitle { color:#8e4593; font-family:$font-titles;font-size:24px;margin-bottom:40px;}

h3.bigtext { color:#8e4593; font-family:$font-titles;font-weight:900;font-size:30px; }

p.copyread.top_section { padding-bottom:.75em;font-weight:300;font-size:120%; }

.responsive-video-wrapper{position:relative;padding-bottom:56.25%;padding-top:35px;height:0;overflow:hidden}
.responsive-video-wrapper iframe{position:absolute;top:0;left:0;width:100%;height:100%}
.img-responsive { border-radius:30px; }
.biosection p { font-size:100%;padding-bottom:.5em; }

hr { border-top: 1px solid #ccc; }

.tangetImages {
    margin-top:25px;
}
.tangetImages img.img-responsive {
    display:block;
    border-radius:0;
    margin:15px auto;
}


.bubble { margin: 20px auto 50px auto; color: #ecebf5; padding: 30px 40px; position: relative; text-align: left; border-radius:30px;}
.bubble, .bubble.drop { background: #86588a; }
.bubble:after { content: ""; position: absolute; bottom:-45px; left:50px; border-width:0 0 45px 45px; border-style: solid; border-color: transparent #86588a; }
.section .title.small { font-size:16px; }
.citation { margin: 20px auto 0; padding: 30px 40px; display:block;padding-top:10px; font-style:italic;font-size:14px;}
